<template>
  <el-dialog
    :title="!dataForm.id ? $t('menus.addMenuInfo') : $t('menus.updateMenuInfo')"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
    :lock-scroll="false"
    :visible.sync="visible"
  >
    <div :style="{ 'overflow-y': 'scroll' }">
      <el-form
        :model="dataForm"
        :rules="dataRule"
        ref="dataForm"
        v-loading="loading"
        label-width="80px"
      >
        <el-form-item :label="$t('menus.type')" prop="type">
          <el-radio-group v-model="dataForm.type">
            <template v-for="(type, index) in dataForm.typeList">
              <el-radio v-if="type != ''" :label="index" :key="type">{{
                type
              }}</el-radio>
            </template>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          :label="dataForm.typeList[dataForm.type]  + $t('menus.name')"
          prop="name"
        >
          <el-input
            v-model="dataForm.name"
            :placeholder="dataForm.typeList[dataForm.type]  + $t('menus.name')"
          ></el-input>
        </el-form-item>
        <el-form-item
            :label="dataForm.typeList[dataForm.type]  + $t('menus.enName')"
            prop="enName"
          >
            <el-input
              v-model="dataForm.enName"
              :placeholder="dataForm.typeList[dataForm.type]  + $t('menus.enName')"
            ></el-input>
          </el-form-item>

        <el-form-item :label="$t('menus.parentMenu')" prop="parentName">
          <el-popover
            ref="menuListPopover"
            placement="bottom-start"
            v-model="menuSelectShow"
            trigger="click"
          >
            <div style="height:300px;overflow-y:auto;">
              <!--  <el-scrollbar name="2" :native="false"> -->
              <el-tree
                :data="menuList"
                :props="menuListTreeProps"
                node-key="menuId"
                ref="menuListTree"
                @current-change="menuListTreeCurrentChangeHandle"
                :default-expand-all="false"
                :accordion="true"
                :highlight-current="true"
                :expand-on-click-node="false"
              >
              </el-tree>
              <!-- </el-scrollbar> -->
            </div>
          </el-popover>
          <el-input
            v-model="dataForm.parentName"
            v-popover:menuListPopover
            :readonly="true"
            placeholder="点击选择上级菜单"
            class="menu-list__input"
          ></el-input>
        </el-form-item>
        <el-form-item label="应用标识" prop="app" >
          <el-input
            v-model="dataForm.app"
            placeholder="请输入在主应用中注册的应用标识"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="dataForm.type > 0 && dataForm.type < 3"
          :label="$t('menus.routeName')"
          prop="routeName"
        >
          <el-input
            v-model="dataForm.routeName"
            :placeholder="$t('menus.routeName')"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="dataForm.type > 0 && dataForm.type < 3"
          :label="$t('menus.filePath')"
          prop="componentUrl"
        >
          <el-input
            v-model="dataForm.componentUrl"
            :placeholder="$t('menus.filePath') + ',relative to the modules path'"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="dataForm.type > 0 && dataForm.type < 3"
          :label="$t('menus.url')"
          prop="url"
        >
          <el-input v-model="dataForm.url" :placeholder="$t('menus.url')"></el-input>
        </el-form-item>
        <!--  <el-form-item v-if="dataForm.type > 0 "  label="是否单路由" >
        <el-switch
          v-model="dataForm.routeOnly"
          :active-value="2"
          :inactive-value="1"
          active-text="是"
          inactive-text="否">
        </el-switch>
      </el-form-item> -->
        <el-form-item v-if="dataForm.type > 0" :label="$t('menus.authTag')" prop="perms">
          <el-input
            v-model="dataForm.perms"
            placeholder="eg: user:list,user:create"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('menus.number')" prop="orderNum">
          <el-input-number
            v-model="dataForm.orderNum"
            controls-position="right"
            :min="0"
          ></el-input-number>
        </el-form-item>

        <el-form-item :label="$t('menus.isEnable')">
          <el-switch
            v-model="dataForm.enabled"
            :active-value="1"
            :inactive-value="0"
            :active-text="$t('menus.enable')"
            :inactive-text="$t('menus.disable')"
          >
          </el-switch>
        </el-form-item>
        <el-form-item v-if="dataForm.type < 2" :label="$t('menus.menuIcon')" prop="icon">
          <el-row>
            <el-col :span="22">
              <el-popover
                ref="iconListPopover"
                placement="bottom-start"
                trigger="click"
                popper-class="mod-menu__icon-popover"
              >
                <div class="mod-menu__icon-list">
                  <el-button
                    v-for="item in iconList"
                    :key="item.icon"
                    slot="reference"
                    @click="iconActiveHandle(item.icon)"
                    :class="{ 'is-active': item.icon === dataForm.icon }"
                  >
                    <!--  <span :class="['icon' ,'iconfont', item.icon]"></span> -->
                    <icon-svg :name="item.icon"></icon-svg>
                  </el-button>
                </div>
              </el-popover>
              <el-input
                v-model="dataForm.icon"
                v-popover:iconListPopover
                :placeholder="$t('menus.menuIcon')"
                class="icon-list__input"
              ></el-input>
            </el-col>
            <el-col :span="2" class="icon-list__tips">
              <el-tooltip placement="top" effect="light">
                <div slot="content">{{ $t('menus.recomInfo') }} SVG Sprite</div>
                <i class="el-icon-warning"></i>
              </el-tooltip>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <div class="mod-footer">
        <el-button @click="visible = false">{{ $t('cancel') }}</el-button>
        <el-button type="primary" :disabled="loading" @click="dataFormSubmit()"
          >{{ $t('confirm') }}</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { treeDataTranslate } from "@/utils";
import iconjson from "@/assets/fonts/iconfont.json";
import { getById , selectList, saveOrUpdate } from '@/api/sys/menu'
export default {
  data() {
    var validateUrl = (rule, value, callback) => {
      if (this.dataForm.type === 1 && !/\S/.test(value)) {
        callback(this.$t('menus.menuUrlNotBlank'));
      } else {
        callback();
      }
    };
    var validateRouteName = (rule, value, callback) => {
      if (this.dataForm.type === 1 && !/\S/.test(value)) {
        callback(this.$t('menus.routeNameNotBlank'));
      } else {
        callback();
      }
    };
    var validateComponentUrl = (rule, value, callback) => {
      if (this.dataForm.type === 1 && !/\S/.test(value)) {
        callback(this.$t('menus.fileNotBlank'));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      visible: false,
      menuSelectShow: false,
      dataForm: {
        id: 0,
        type: 1,
        typeList: [this.$t('menus.dic'), this.$t('menus.menu'), "", this.$t('menus.button')],
        name: "",
        enName:'',
        parentId: 0,
        parentName: "",
        app: "",
        url: "",
        perms: "",
        orderNum: 0,
        icon: "",
        enabled: 1,
        routeName: "",
        componentUrl: "",
        routeOnly: 1,
        iconList: []
      },
      dataRule: {
        name: [
          { required: true, message: this.$t('menus.menuNameNotBlank'), trigger: "blur" }
        ],
        enName: [
          { required: true, message: "菜单英文名称不能为空", trigger: "blur" }
        ],
        parentName: [
          { required: true, message: this.$t('menus.parentNameNotBlank'), trigger: "change" }
        ],
        url: [{ validator: validateUrl, trigger: "blur" }],
        componentUrl: [{ validator: validateComponentUrl, trigger: "blur" }],
        routeName: [{ validator: validateRouteName, trigger: "blur" }]
      },
      menuList: [],
      menuListTreeProps: {
        label: "name",
        children: "children"
      }
    };
  },
  computed: {
    //计算内部元素的高度
    asideHeight: function() {
      return document.documentElement["clientHeight"] - 110 - 25;
    }
  },
  // activated () {
  //   const id = this.$route.query.id
  //   this.init(id)
  //   this.loading = false
  // },
  created() {
    const icons = iconjson.glyphs;
    this.iconList = icons.map(t => {
      t.icon = "icon-" + t.font_class;
      return t;
    });
  },
  props: {
    parentMenuNode : Object
  },
  watch: {
    parentMenuNode: {
      handler(newVal, oldVal) {
        // let parentMenu = newVal;
        // debugger
        // if(parentMenu) {
        //   this.dataForm.parentId = parentMenu.menuId;
        //   this.dataForm.parentName = parentMenu.name;
        //   if(parentMenu.type === '2') {
        //     this.dataForm.type = '3';
        //   }
        // }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    initByparentMenuNode() {
      let parentMenu = this.parentMenuNode; 
      if(parentMenu) {
        this.dataForm.parentId = parentMenu.menuId;
        this.dataForm.parentName = parentMenu.name;
        debugger
        if(parentMenu.type === 1) {
          this.dataForm.type = 3;
        }
      }
    },
    init(id) {
      this.dataForm.id = id;
      this.visible = true;
      
      selectList().then(({ data }) => {
          // 判断当前是菜单还是工具栏

          this.menuList = treeDataTranslate(data.data, "menuId");
        })
        .then(() => {
          this.$nextTick(() => {
            this.$refs["dataForm"].resetFields();
          });
        })
        .then(() => {
          if (!this.dataForm.id) {
            // 新增
            this.initByparentMenuNode();
            this.menuListTreeSetCurrentNode();
          } else {
            // 修改
             
            getById(this.dataForm.id).then(({ data }) => {
              this.dataForm.id = data.data.menuId;
              this.dataForm.type = data.data.type;
              this.dataForm.name = data.data.name;
              this.dataForm.enName = data.data.enName;
              this.dataForm.parentId = data.data.parentId;
              this.dataForm.url = data.data.url;
              this.dataForm.app = data.data.app;
              this.dataForm.perms = data.data.perms;
              this.dataForm.orderNum = data.data.orderNum;
              this.dataForm.icon = data.data.icon;
              this.dataForm.enabled = data.data.enabled;
              this.dataForm.componentUrl = data.data.componentUrl;
              this.dataForm.routeName = data.data.routeName;

              this.menuListTreeSetCurrentNode();
            });
          }
        });
    },
    // 菜单树选中
    menuListTreeCurrentChangeHandle(data, node) {
      this.dataForm.parentId = data.menuId;
      this.dataForm.parentName = data.name;

      this.menuSelectShow = false;
    },
    // 菜单树设置当前选中节点
    menuListTreeSetCurrentNode() {
      this.$refs.menuListTree.setCurrentKey(this.dataForm.parentId);
      this.dataForm.parentName = (this.$refs.menuListTree.getCurrentNode() ||
        {})["name"];
    },
    // 图标选中
    iconActiveHandle(iconName) {
      this.dataForm.icon = iconName;
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          this.loading = true;
          
          const data_ = {
              menuId: this.dataForm.id || undefined,
              type: this.dataForm.type,
              name: this.dataForm.name,
              enName:this.dataForm.enName,
              parentId: this.dataForm.parentId,
              url: this.dataForm.url,
              app: this.dataForm.app,
              perms: this.dataForm.type === 0? '': this.dataForm.perms,
              enabled: this.dataForm.enabled,
              orderNum: this.dataForm.orderNum,
              routeName: this.dataForm.type === 0? '': this.dataForm.routeName ,
              componentUrl: this.dataForm.type === 0? '': this.dataForm.componentUrl,
              icon: this.dataForm.icon,
              routeOnly: this.dataForm.routeOnly
            }
          saveOrUpdate(data_).then(({ data }) => {
              if (data && data.code === 0) {
                this.$message({
                  message: "操作成功",
                  type: "success",
                  duration: 1000
                });

                this.visible = false;
                this.$emit("refreshDataList");
              } else {
                this.$message.error(data.msg);
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>

<style lang="scss">
.mod-menu {
  .menu-list__input,
  .icon-list__input {
    > .el-input__inner {
      cursor: pointer;
    }
  }
  &__icon-popover {
    max-width: 450px;
  }
  &__icon-list {
    max-height: 240px;
    padding: 0;
    overflow-y: auto;
    margin: -8px 0 0 -8px;

    > .el-button {
      padding: 8px;
      margin: 8px 0 0 8px;
      > span {
        display: inline-block;
        vertical-align: middle;
        width: 24px;
        height: 24px;
        font-size: 26px;
      }

      .iconfont {
        font-size: 26px;
      }
    }
  }
  .icon-list__tips {
    font-size: 18px;
    text-align: center;
    color: #e6a23c;
    cursor: pointer;
  }

  .hover-iconfont {
    font-size: 30px;
  }
}
</style>
